import './scripts/common';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init({
    counter: true,
    appendCounterTo: '.lg-content'
});

import * as lightboxCounterIndicator from './scripts/lightbox-counter-indicator';
lightboxCounterIndicator.init();

import * as scrollHint from './scripts/scroll-hint';
scrollHint.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element)
});