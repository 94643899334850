"use strict";

import {find} from "@elements/dom-utils"

const isMobile = () => matchMedia('(max-width: 767px)').matches;

export function init() {
    let hero = find('.js-scroll-hint--hero');
    let offset = isMobile() ? hero.offsetHeight - 60 : window.innerHeight - 84;
    let scrollHint = find('.js-scroll-hint');
    scrollHint.addEventListener('click', function () {
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        });
    });
}